const baseUrl: string = process.env.NODE_ENV === "development" ? "https://localhost:7117" : "https://api-b.oc6.dk";

export class NotificationsController {
    public async register(subscription: PushSubscriptionJSON): Promise<boolean> {
        const requestInit: RequestInit = {
            method: "POST",
            body: JSON.stringify(subscription),
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
        };

        const response: Response = await window.fetch(baseUrl + "/api/Notifications/Register", requestInit);

        return response.status === 200;
    }
}