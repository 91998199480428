import React, { useState } from "react";
import { BS, Classes } from "../models/Bootstrap";
import { ConsumptionDto } from "../models/ConsumptionDto";

import "../css/fitted-image.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    consumption: ConsumptionDto;
    deleteConsumption: (id: string) => void;
}

export function ImageCard(props: IProps) {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const { consumption, deleteConsumption } = props;

    function toTwoDigits(time: number): string {
        if (time < 10) {
            return "0" + time.toString();
        }

        return time.toString();
    }

    function handleDeleteConsumption() {
        if (window.confirm(`Slet for ${getDayString()}?`)) {
            deleteConsumption(consumption.id);
        }
    }

    function getDayString(): string {
        const day: string = consumption.day.toString();
        const month: string = consumption.month.toString();
        const year: string = consumption.year.toString();

        const minute: string = toTwoDigits(consumption.minute);
        const hour: string = toTwoDigits(consumption.hour);

        return `${day}/${month}-${year} kl. ${hour}:${minute}`;
    }

    return (
        <div className={Classes(BS.card, BS.shadow, BS.mt_3, BS.p_3)} >
            <span className={BS.h1}>
                {getDayString()}
            </span>
            <div className={Classes(BS.row, BS.gx_1)}>
                <div className={BS.col_9}>
                    <button className={Classes(BS.btn, BS.btn_primary, BS.w_100, BS.p_3, BS.fs_3)} onClick={() => setIsVisible(!isVisible)}>
                        {isVisible ? "Skjul billede" : "Se billede"}
                    </button>
                </div>
                <div className={BS.col_3}>
                    <button
                        className={Classes(BS.w_100, BS.btn, BS.btn_danger, BS.p_3, BS.fs_3)}
                        onClick={() => handleDeleteConsumption()}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            </div>
            {isVisible ? <img src={consumption.imageUrl} className="fitted-image" /> : <></>}
        </div>
    );
}