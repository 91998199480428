import { ConsumptionDto } from "../models/ConsumptionDto";

const baseUrl: string = process.env.NODE_ENV === "development" ? "https://localhost:7117" : "https://api-b.oc6.dk";

export class ConsumptionsController {
    public async isConsumed(): Promise<boolean> {
        const requestInit: RequestInit = {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
        };

        const response: Response = await window.fetch(baseUrl + "/api/Consumptions/IsConsumed", requestInit);

        return response.status === 200;
    }

    public async deleteConsumption(id: string): Promise<boolean> {
        const requestInit: RequestInit = {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
        };

        const response: Response = await window.fetch(baseUrl + "/api/Consumptions/" + id, requestInit);

        return response.status === 200;
    }

    public async get(): Promise<Array<ConsumptionDto>> {
        const requestInit: RequestInit = {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
        };

        const response: Response = await window.fetch(baseUrl + "/api/Consumptions/", requestInit);

        const consumptions: Array<ConsumptionDto> = await response.json();

        return consumptions;
    }

    public async consume(dataUrl: string): Promise<boolean> {
        const requestInit: RequestInit = {
            method: "POST",
            body: JSON.stringify({ dataUrl }),
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
        };

        const response: Response = await window.fetch(baseUrl + "/api/Consumptions/Consume", requestInit);

        return response.status === 200;
    }
}