import React from "react";
import { ImageCard } from "./components/ImageCard";
import { Spinner } from "./components/Spinner";
import { ConsumptionsController } from "./data/ConsumptionsController";
import { NotificationsController } from "./data/NotificationsController";
import { BS, Classes } from "./models/Bootstrap";
import { ConsumptionDto } from "./models/ConsumptionDto";
import { LoadableObject } from "./models/LoadableObject";
import { LoadableObjectState } from "./models/LoadableObjectState";
import { registerPushNotification } from "./push/registerPushNotification";

const consumptionsController: ConsumptionsController = new ConsumptionsController();
const notificationsController: NotificationsController = new NotificationsController();

function App() {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [cameraIsStarted, setCameraIsStarted] = React.useState<boolean>(false);
  const [consumptions, setConsumptions] = React.useState<LoadableObject<Array<ConsumptionDto>>>({ state: LoadableObjectState.Init, data: [] });
  const [isConsumed, setIsConsumed] = React.useState<LoadableObject<boolean>>({ state: LoadableObjectState.Init, data: false });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [subscription, setSubscription] = React.useState<LoadableObject<PushSubscriptionJSON | null>>({ state: LoadableObjectState.Init, data: null });

  React.useEffect(() => {
    window.addEventListener("focus", () => loadInBackground());
  }, []);

  async function loadInBackground() {
    await loadIsConsumedInBackground();

    await loadConsumptionsInBackground();
  }

  React.useEffect(() => {
    if (subscription.state === LoadableObjectState.Init) {
      loadSubscription();
    }

  }, [subscription]);

  React.useEffect(() => {
    if (consumptions.state === LoadableObjectState.Init) {
      loadConsumptions();
    }
  }, [consumptions]);

  React.useEffect(() => {
    if (isConsumed.state === LoadableObjectState.Init) {
      loadIsConsumed();
    }
  }, [isConsumed]);

  async function loadSubscription() {
    setSubscription({
      ...subscription,
      state: LoadableObjectState.Loading
    });

    const sub = await registerPushNotification();

    setSubscription({
      ...subscription,
      state: LoadableObjectState.Loaded,
      data: sub
    });

    if (sub) {
      notificationsController.register(sub);
    }
  }

  async function loadIsConsumed(): Promise<void> {
    setIsConsumed({
      ...isConsumed,
      state: LoadableObjectState.Loading
    });

    await loadIsConsumedInBackground();
  }

  async function loadIsConsumedInBackground(): Promise<void> {
    try {
      const value = await consumptionsController.isConsumed();

      if (isConsumed.state === LoadableObjectState.Loaded) {
        if (value !== isConsumed.data) {
          setIsConsumed({
            ...isConsumed,
            data: value,
            state: LoadableObjectState.Loaded
          });
        }
      }
      else {
        setIsConsumed({
          ...isConsumed,
          data: value,
          state: LoadableObjectState.Loaded
        });
      }
    }
    catch (err) {
      setIsConsumed({
        ...isConsumed,
        state: LoadableObjectState.Error
      });
    }
  }

  async function loadConsumptions(): Promise<void> {
    setConsumptions({
      ...consumptions,
      state: LoadableObjectState.Loading
    });

    await loadConsumptionsInBackground();
  }

  async function loadConsumptionsInBackground(): Promise<void> {
    try {
      const value = await consumptionsController.get();

      if (consumptions.state === LoadableObjectState.Loaded) {

        const isSame = value.length === consumptions.data.length
          && value.reduce<boolean>((prev: boolean, current: ConsumptionDto) => {
            return prev && (!!consumptions.data.find(x => x.id === current.id));
          }, true);

        if (!isSame) {
          setConsumptions({
            ...consumptions,
            data: value,
            state: LoadableObjectState.Loaded
          });
        }
      }
      else {
        setConsumptions({
          ...consumptions,
          data: value,
          state: LoadableObjectState.Loaded
        });
      }
    }
    catch (err) {
      setConsumptions({
        ...consumptions,
        state: LoadableObjectState.Error
      });
    }
  }

  function startCamera() {
    if (!cameraIsStarted) {
      const { current: video } = videoRef;

      if (video) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            video.srcObject = stream;
            video.play();
          })
          .catch((err) => {
            console.error(`An error occurred: ${err}`);
          });

        video.addEventListener("canplay", () => setCameraIsStarted(true));
      }
    }
  }

  async function consume() {
    const { current: video } = videoRef;

    if (!video) {
      return;
    }

    const canvas = document.createElement("canvas") as HTMLCanvasElement;
    const context = canvas.getContext("2d");

    if (!context) {
      return;
    }

    setIsLoading(true);

    const width = 500;
    const height = (video.videoHeight / video.videoWidth) * 500;

    canvas.width = width;
    canvas.height = height;

    context.drawImage(video, 0, 0, width, height);
    const dataURL = canvas.toDataURL("image/jpeg");

    if (typeof (dataURL) === "string") {
      await consumptionsController.consume(dataURL);

      setConsumptions({
        ...consumptions,
        data: [],
        state: LoadableObjectState.Init
      });

      setIsConsumed({
        ...isConsumed,
        data: true,
        state: LoadableObjectState.Loaded
      });
    }

    setIsLoading(false);
  }

  switch (consumptions.state) {
    case LoadableObjectState.Init:
    case LoadableObjectState.Loading: {
      return <Spinner />;
    }
    case LoadableObjectState.Error: {
      return <h1>Fejl ved indlæsning...</h1>;
    }
  }

  switch (isConsumed.state) {
    case LoadableObjectState.Init:
    case LoadableObjectState.Loading: {
      return <Spinner />;
    }
    case LoadableObjectState.Error: {
      return <h1>Fejl ved indlæsning...</h1>;
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  function getButtonText(): string {
    if (isConsumed.data) {
      return "Pillen er taget i dag";
    }
    else if (!cameraIsStarted) {
      return "Start kamera";
    }
    else {
      return "Tag billede";
    }
  }

  async function deleteConsumption(id: string) {
    if (await consumptionsController.deleteConsumption(id)) {
      await loadIsConsumedInBackground();
      await loadConsumptionsInBackground();
    }
    else {
      console.log("ikke ok");
    }
  }

  function btnClicked() {
    if (cameraIsStarted) {
      consume();
    }
    else {
      startCamera();
    }
  }

  return (
    <div className={Classes(BS.container)}>
      <div className={BS.row}>
        <div className={Classes(BS.col_12, BS.mb_3, BS.justify_content_center, BS.d_flex)}>
          <video id="video" ref={videoRef} width={500} />
        </div>
        <div className={Classes(BS.col_12, BS.mb_3)}>
          <button
            type="button"
            onClick={() => btnClicked()}
            disabled={isConsumed.data}
            className={Classes(BS.btn, BS.btn_success, BS.w_100, BS.p_3, BS.fs_1)}>
            {getButtonText()}
          </button>
        </div>
      </div>
      {consumptions.data.map((consumption: ConsumptionDto) => {

        return (
          <ImageCard
            key={consumption.imageUrl}
            deleteConsumption={(id: string) => deleteConsumption(id)}
            consumption={consumption} />
        );
      })}
    </div>
  );
}

export default App;