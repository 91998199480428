export async function registerPushNotification(): Promise<PushSubscriptionJSON | null> {
    try {
        if (navigator?.serviceWorker) {
            const reg = await navigator.serviceWorker.ready;

            const sub = await reg.pushManager.getSubscription();

            if (sub) {
                return sub.toJSON();
            }
            else {
                const registration = await navigator.serviceWorker.getRegistration();

                if (registration) {
                    const publicKey = "BLDobHEQrvD4GbqY0vQJKwCzZkKMgkKR2oM3chRgshA5V1A7_Ojsu-Z0BGN63WjdFnCRCBtJAJ-WYfdGMPUeOuk";
                    const applicationServerKey = urlB64ToUint8Array(publicKey);

                    const subscription = await registration.pushManager
                        .subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: applicationServerKey
                        });

                    return subscription.toJSON();
                }
                else {
                    return null;
                }
            }
        }
        else {
            return null;
        }
    }
    catch {
        return null;
    }
}

function urlB64ToUint8Array(base64String: string) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);

    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
};