import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import "../css/rotate.css";
import { BS, Classes } from "../models/Bootstrap";

export function Spinner() {
    return (
        <div className={BS.container_fluid}>
            <div className={Classes(BS.w_100, BS.mt_5, BS.d_flex, BS.justify_content_center, BS.align_items_center)}>
                <FontAwesomeIcon
                    className={Classes("rotate", BS.h1)}
                    icon={faSpinner} />
            </div>
        </div>
    );
}